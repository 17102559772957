import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

const datadogConfig: RumInitConfiguration = {
	applicationId: '213c3eb5-7e09-4800-b35d-8c668d69a306',
	clientToken: 'pub3df4eb23494c49d2b41ee66d18fb857c',
	site: 'datadoghq.com',
	service: 'fe-main',
	enableExperimentalFeatures: ['clickmap'],
	env: window.ENV,
	sessionSampleRate: 10,
	sessionReplaySampleRate: 100,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: window.ENV === 'stage' ? 'mask-user-input' : 'mask',
	version: `${window.BUILD_VERSION}`,
};

if (window.SESSION_USER && (window.ENV === 'stage' || window.ENV === 'prod')) {
	datadogRum.init(datadogConfig);
	datadogRum.startSessionReplayRecording();
}
