import { Icon, IconV2 } from '@bamboohr/fabric';
import { render } from 'base/wrapped-render';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { createLogger } from '@bamboohr/utils/lib/dev-logger';
/** @typedef {import('@bamboohr/fabric').IconV2Props} IconV2Props */
import {
	ENCORE_ICON_CLASS_ATTRIBUTE,
	ENCORE_ICON_COLOR_ATTRIBUTE,
	ENCORE_ICON_NAME_ATTRIBUTE,
	ENCORE_ICON_SIZE_ATTRIBUTE,
	ICON_BRAND_ATTRIBUTE,
	ICON_NAME_ATTRIBUTE
} from './constants';

const logger = createLogger('ba-icon')

export default class BaIcon {
	constructor(element) {
		/** @type {HTMLElement} */
		this._element = element;
	}

	connectedCallback = ifFeature(
		'encore',
		() => {
			this._injectSvg();
			this._element.setAttribute('class', this._element.getAttribute(ENCORE_ICON_CLASS_ATTRIBUTE) ?? '');
		},
		() => {
			const element = this._element;
			this._injectSvg(element.getAttribute(ICON_NAME_ATTRIBUTE));
		}
	);

	attributeChangedCallback = ifFeature(
		'encore',
		(attributeName, oldValue, newValue) => {
			if ([ENCORE_ICON_NAME_ATTRIBUTE, ENCORE_ICON_COLOR_ATTRIBUTE, ENCORE_ICON_SIZE_ATTRIBUTE].includes(attributeName)) {
				this._injectSvg();
			} else if (attributeName === ENCORE_ICON_CLASS_ATTRIBUTE) {
				this._element.setAttribute('class', newValue || '');
			}
		},
		(attributeName) => {
			if (ICON_NAME_ATTRIBUTE === attributeName || ICON_BRAND_ATTRIBUTE === attributeName) {
				this._injectSvg(this._element.getAttribute(ICON_NAME_ATTRIBUTE));
			}
		}
	);

	/**
	 * Injects a Fabric icon into this element
	 * @param  {string=} name The name of the icon
	 */
	_injectSvg = ifFeature(
		'encore',
		() => {
			let name = /** @type {IconV2Props['name'] | null} */ (
				this._element.getAttribute(ENCORE_ICON_NAME_ATTRIBUTE)
			);
			let size = /** @type {IconV2Props['size'] | null} */ (
				parseInt(this._element.getAttribute(ENCORE_ICON_SIZE_ATTRIBUTE) ?? '', 10)
			);

			if (!name || !size || Number.isNaN(size)) {
				logger.error('name and size are required')
				name ||= 'square-full-solid';
				size ||= 16;
			}

			const color = this._element.getAttribute(ENCORE_ICON_COLOR_ATTRIBUTE) || undefined;
			render(<IconV2 color={color} name={name} size={size} />, this._element);
		},
		(name) => {
			const brand = this._element.getAttribute('brand') === 'true';
			render(<Icon brand={brand} name={name} />, this._element);
		}
	);
}
